import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import { CreditExpenditureItem } from 'lib/Model/Wallet';
import { format, parseISO } from 'date-fns';
import { DateFormat } from 'config';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  positiveAmount: {
    color: 'green',
    textTransform: 'uppercase',
  },
  negativeAmount: {
    color: 'red',
    textTransform: 'uppercase',
  },
}));

interface WalletExpenditureDialogProps {
  open: boolean;
  expenditureData: CreditExpenditureItem[];
  loading: boolean;
  error: string | null;
  onClose: () => void;
}

const WalletExpenditureDialog: React.FC<WalletExpenditureDialogProps> = ({
  open,
  expenditureData,
  loading,
  error,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="expenditure-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="expenditure-dialog-title">
        Expenditure Details
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Skeleton variant="rect" width="100%" height={200} />
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : expenditureData.length > 0 ? (
          <Table className={classes.table} aria-label="expenditure table">
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Created On</TableCell>
                <TableCell>Title</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expenditureData.map((item) => (
                <TableRow key={item.id}>
                  <TableCell
                    className={
                      item.amount > 0
                        ? classes.positiveAmount
                        : item.amount < 0
                        ? classes.negativeAmount
                        : undefined
                    }
                  >
                    {item.amount > 0 ? `+${item.amount}` : item.amount}
                  </TableCell>
                  <TableCell>
                    {item.created_on
                      ? format(parseISO(item.created_on), DateFormat.EXCEL)
                      : 'N/A'}
                  </TableCell>
                  <TableCell>{item.title || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>No expenditure data available.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WalletExpenditureDialog;
