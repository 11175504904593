import {
  AppBar,
  Button,
  Card,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { DatePicker, PageableTable, RelatedCompanyChip } from 'components';
import { PageableTableHeader } from 'components/PageableTable/components';
import { DateFormat } from 'config';
import { endOfYear, format, parseISO, startOfDay, startOfYear } from 'date-fns';
import {
  fetchCreditInvoice,
  fetchSubCompanies,
  fetchTalexioParent,
  fetchWalletBalance,
  fetchWalletTransactions,
  fetchCreditExpenditure,
} from 'lib/Api/Wallet';
import {
  PagedWallet,
  TalexioParent,
  TalexioSubcompanies,
  WalletBalance,
  WalletInvoice,
  CreditExpenditureItem,
} from 'lib/Model/Wallet';
import * as React from 'react';
import WalletInvoicesDialog from './WalletInvoicesDialog';
import { Alert, Skeleton } from '@material-ui/lab';
import { fetchJobDetails } from 'lib/Api/Jobs';
import JobDetailsDialog from './JobDetailsDialog';
import { Job } from 'lib/Model/Job';
import WalletSubcompaniesDialog from './WalletSubcompaniesDialog';
import { useLocation } from 'react-router-dom';
import {
  useLocalStorageSettings,
  TableSettings,
  getTableSettingsStorageKey,
} from 'hooks';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import WalletExpenditureDialog from './WalletExpenditureDialog';

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2),
  },
  boldTitle: {
    fontWeight: 500,
    padding: 0,
    clear: 'both',
    display: 'block',
    cursor: 'default',
    textAlign: 'left',
  },
  formControl: {
    marginBottom: theme.spacing(2),
  },
  datePickerFormControl: {
    padding: theme.spacing(1),
  },
  clickable: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    textAlign: 'center',
  },
  mainColumn: {
    width: '15%',
    paddingRight: 0,
  },
  secondaryColumn: {
    width: '10%',
    paddingRight: 0,
  },
  tertiaryColumn: {
    width: '5%',
    paddingRight: 0,
  },
}));

interface Props {
  companyId: string;
  withRelated: boolean;
}

export const Wallet: React.FC<Props> = ({ companyId, withRelated }) => {
  const [loading, setLoading] = React.useState(false);
  const [appBarLoading, setAppBarLoading] = React.useState(false);
  const classes = useStyles();
  const today = React.useMemo(() => startOfDay(new Date()), []);
  const [expenditureDialogOpen, setExpenditureDialogOpen] =
    React.useState(false);
  const [expenditureData, setExpenditureData] = React.useState<
    CreditExpenditureItem[]
  >([]);
  const [expenditureLoading, setExpenditureLoading] = React.useState(false);
  const [expenditureError, setExpenditureError] = React.useState<string | null>(
    null,
  );

  const [pageNumber, setPageNumber] = React.useState(0);
  // const [pageSize, setPageSize] = React.useState(10);
  const [dateFrom, setDateFrom] = React.useState<string | undefined>(
    startOfYear(today).toISOString(),
  );
  const [dateTo, setDateTo] = React.useState<string | undefined>(
    endOfYear(today).toISOString(),
  );

  const { pathname, hash } = useLocation();
  const [tableSettings, setTableSettings] =
    useLocalStorageSettings<TableSettings>(
      getTableSettingsStorageKey(pathname, hash),
      { rowsPerPage: 25 },
    );

  const [data, setData] = React.useState<PagedWallet | undefined>();
  const [balance, setBalance] = React.useState<WalletBalance | undefined>();
  const [parent, setParent] = React.useState<TalexioParent[]>([]);
  const [showPurchases, setShowPurchases] = React.useState(false);
  const [subCompanies, setSubCompanies] = React.useState<TalexioSubcompanies[]>(
    [],
  );
  const [dialogDataLoading, setDialogDataLoading] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [viewMoreDialogOpen, setViewMoreDialogOpen] = React.useState(false);
  const [creditInvoices, setCreditInvoices] = React.useState<WalletInvoice[]>(
    [],
  );
  const [subCompaniesDialogOpen, setSubCompaniesDialogOpen] =
    React.useState(false);
  const handleShowExpenditure = async (creditId: string) => {
    setExpenditureDialogOpen(true);
    setExpenditureLoading(true);
    setExpenditureError(null);

    try {
      const data = await fetchCreditExpenditure(creditId);
      setExpenditureData(data);
    } finally {
      setExpenditureLoading(false);
    }
  };

  const [selectedJob, setSelectedJob] = React.useState<Job[]>();
  const [currentCreditDate, setCurrentCreditDate] = React.useState('');

  const fetchData = React.useCallback(async () => {
    setLoading(true);
    const walletData = await fetchWalletTransactions(
      companyId,
      pageNumber + 1,
      tableSettings.rowsPerPage,
      showPurchases,
      dateFrom,
      dateTo,
    );
    setData(walletData);
    setLoading(false);

    setAppBarLoading(true);
    const parentData = await fetchTalexioParent(companyId);
    setParent(parentData);
    const balanceData = await fetchWalletBalance(companyId);
    setBalance(balanceData[0]);
    setAppBarLoading(false);
  }, [
    setLoading,
    setAppBarLoading,
    setData,
    setBalance,
    setParent,
    pageNumber,
    tableSettings.rowsPerPage,
    companyId,
    dateFrom,
    dateTo,
    showPurchases,
  ]);

  React.useEffect(() => {
    const fetchSubCompaniesData = async () => {
      const subCompaniesData = await fetchSubCompanies(companyId);
      setSubCompanies(subCompaniesData);
    };

    fetchData();
    fetchSubCompaniesData();
  }, [companyId, fetchData]);

  const handleFetchSubCompanies = () => {
    setSubCompaniesDialogOpen(true);
  };

  const handleOpenDialog = async (credit_id: string, createdOn: string) => {
    setCurrentCreditDate(createdOn);
    setDialogOpen(true);
    setDialogDataLoading(true);
    const creditInvoices = await fetchCreditInvoice(credit_id);
    setCreditInvoices(creditInvoices);
    setDialogDataLoading(false);
  };

  const handleViewMoreDialog = async (jobId: string) => {
    const jobDetails = await fetchJobDetails(jobId);
    setSelectedJob(jobDetails);
    setViewMoreDialogOpen(true);
  };

  const columns: PageableTableHeader[] = [
    {
      key: 'creditDate',
      label: 'Date',
      sortable: false,
    },
    {
      key: 'amount',
      label: 'Amount',
      sortable: false,
    },
    {
      key: 'details',
      label: 'Details',
      sortable: false,
    },
    {
      key: 'invoice',
      sortable: false,
    },
  ];

  return (
    <Card className={classes.card}>
      <CardHeader
        title="Client Wallet"
        subheader={
          <span>
            A view of the{' '}
            <a
              href="https://support.talexio.com/hc/en-us/articles/8297856530066-How-much-does-it-cost-to-publish-a-vacancy-on-jobsinmalta-"
              target="_blank"
              rel="noreferrer"
            >
              jobsinmalta credit transactions
            </a>{' '}
            and remaining balance for this client.
          </span>
        }
        action={
          <React.Fragment>
            {subCompanies.length > 0 && (
              <Button color="primary" onClick={handleFetchSubCompanies}>
                View Subcompanies
              </Button>
            )}

            <Button color="primary" href={`/clients/${companyId}#activity`}>
              View Vacancies
            </Button>
          </React.Fragment>
        }
      />

      <Grid container spacing={1}>
        <Grid item xs={12} md={5}>
          <FormControl className={classes.datePickerFormControl} fullWidth>
            <DatePicker
              label={'Date from'}
              margin="none"
              fullWidth
              value={dateFrom}
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={(date: MaterialUiPickersDate) => {
                setDateFrom(date ? date.toISOString() : undefined);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={5}>
          <FormControl className={classes.datePickerFormControl} fullWidth>
            <DatePicker
              label={'Date to'}
              margin="none"
              fullWidth
              value={dateTo}
              inputVariant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={(date: MaterialUiPickersDate) => {
                setDateTo(date ? date.toISOString() : undefined);
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} md={2}>
          <FormControl margin="none" style={{ marginTop: 8, marginLeft: 20 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={showPurchases}
                  onChange={(e, checked) => {
                    setShowPurchases(checked);
                  }}
                />
              }
              label={'Only show purchases'}
            />
          </FormControl>
        </Grid>
      </Grid>

      {(appBarLoading || (balance && (data?.data.length ?? 0) > 0)) && (
        <AppBar
          color="primary"
          position="static"
          style={{ textAlign: 'center' }}
        >
          <Toolbar>
            {appBarLoading ? (
              <Skeleton variant="rect" width="100%" height={50} />
            ) : balance && (data?.data.length ?? 0) > 0 ? (
              <Grid container>
                <Grid item xs={12}>
                  <div>
                    <Typography variant="h6">
                      JIM Credit Balance:{' '}
                      {(balance?.balance ?? 0) > 1000
                        ? '∞'
                        : balance?.balance ?? 'N/A'}
                    </Typography>
                    {balance?.latest_unlimited_expiry && (
                      <div>
                        Based on the latest allocation of credits, this client's
                        last unlimited package expires on{' '}
                        <strong>
                          {format(
                            parseISO(balance.latest_unlimited_expiry),
                            DateFormat.EXCEL,
                          )}
                        </strong>{' '}
                        and <strong>{balance.roles_published}</strong> roles
                        have been published under this package.
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
            ) : null}
          </Toolbar>
        </AppBar>
      )}

      {(data?.data.length ?? 0) === 0 && parent && parent.length > 0 ? (
        <Alert severity="info">
          This is a subcompany of {parent[0].company_title}. View their wallet
          transactions{' '}
          <a
            href={`https://intranet.exp42.com/clients/${parent[0].tlx_id}#wallet`}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          .
        </Alert>
      ) : null}
      {loading ? (
        <Skeleton variant="rect" width="100%" height={300} />
      ) : (
        <PageableTable
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          showSkeletonLoading
          rows={
            data?.data?.map((d, index) => ({
              key: `wallet_${index}`,
              cells: [
                {
                  key: 'creditDate',
                  display: (
                    <Typography>
                      {d.created_on &&
                        ` ${format(parseISO(d.created_on), DateFormat.EXCEL)}`}
                    </Typography>
                  ),
                },
                {
                  key: 'amount',
                  display: (
                    <React.Fragment>
                      <Typography>
                        <span
                          className={classes.boldTitle}
                          style={
                            d.amount
                              ? {
                                  color:
                                    d.amount > 0
                                      ? 'green'
                                      : d.amount < 0
                                      ? 'red'
                                      : 'inherit',
                                  textTransform: 'uppercase',
                                }
                              : undefined
                          }
                        >
                          {d.amount > 0 ? `+${d.amount}` : d.amount}
                        </span>
                      </Typography>
                      <Typography variant="caption">
                        {d.expiry_date &&
                          `Expires on: ${format(
                            parseISO(d.expiry_date),
                            DateFormat.SHORT,
                          )}`}
                      </Typography>
                    </React.Fragment>
                  ),
                },
                {
                  key: 'summary',
                  display: (
                    <div>
                      {d.amount > 0 ? (
                        <Button
                          onClick={() => handleShowExpenditure(d.credit_id)}
                          style={{ cursor: 'pointer' }}
                          color="secondary"
                        >
                          Show Expenditure
                        </Button>
                      ) : d.resource_id == null && Boolean(d.has_admin_fee) ? (
                        'Purchased with Admin Fee'
                      ) : (
                        <React.Fragment>
                          <Button
                            onClick={() =>
                              d.resource_id &&
                              handleViewMoreDialog(d.resource_id)
                            }
                            style={{ cursor: 'pointer' }}
                            color="primary"
                          >
                            {d.job_title}
                          </Button>
                          <div />
                          <RelatedCompanyChip
                            companyId={d.client_id}
                            companyName={d.company_title}
                          />
                        </React.Fragment>
                      )}
                    </div>
                  ),
                },
                {
                  key: 'invoice',
                  display: Boolean(d.number_of_invoices) &&
                    d.number_of_invoices !== undefined && (
                      <Button
                        className={classes.clickable}
                        onClick={() =>
                          handleOpenDialog(d.credit_id, d.created_on)
                        }
                      >
                        View Invoice
                      </Button>
                    ),
                },
              ],
            })) ?? []
          }
          loading={loading}
          pageNumber={pageNumber}
          rowCount={data?.total_count ?? 0}
          columns={columns}
          onChangePage={setPageNumber}
        />
      )}

      <WalletInvoicesDialog
        open={dialogOpen}
        createdOn={currentCreditDate}
        invoices={creditInvoices}
        onClose={() => setDialogOpen(false)}
        loading={dialogDataLoading}
      />
      <WalletSubcompaniesDialog
        open={subCompaniesDialogOpen}
        subCompanies={subCompanies}
        onClose={() => setSubCompaniesDialogOpen(false)}
        loading={dialogDataLoading}
      />

      <WalletExpenditureDialog
        open={expenditureDialogOpen}
        expenditureData={expenditureData}
        loading={expenditureLoading}
        error={expenditureError}
        onClose={() => setExpenditureDialogOpen(false)}
      />

      <JobDetailsDialog
        open={viewMoreDialogOpen}
        job={selectedJob!}
        loading={dialogDataLoading}
        onClose={() => setViewMoreDialogOpen(false)}
      />
    </Card>
  );
};
