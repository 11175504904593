import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import { HelpRequest } from 'lib/Model/Helpbot';

export async function streamMessageToHelpbot(
  request: HelpRequest,
  onChunk: (chunk: string) => void,
): Promise<void> {
  const response = await fetch(`${Config.API_BASE_URL}intranet/help`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'access-token': getAccessToken() ?? '',
    },
    body: JSON.stringify(request),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const reader = response.body?.getReader();
  if (!reader) {
    throw new Error('No readable stream found on response');
  }

  const decoder = new TextDecoder('utf-8');

  let done = false;
  while (!done) {
    const { value, done: doneReading } = await reader.read();
    if (doneReading) {
      done = true;
      break;
    }
    const chunkValue = decoder.decode(value, { stream: true });
    onChunk(chunkValue);
  }
}

export function createSupportTicket(
  sessionId: string,
  subject?: string,
): Promise<{ message: string }> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/help/ticket`,
      { session_id: sessionId, subject },
      {
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data);
}
