import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import {
  Wallet,
  PagedWallet,
  WalletBalance,
  WalletInvoice,
  TalexioParent,
  TalexioSubcompanies,
  CreditExpenditureItem,
} from 'lib/Model/Wallet';

export function fetchWalletTransactions(
  companyId: string,
  pageNumber: number,
  itemsPerPage: number,
  showPurchases: boolean,
  dateFrom?: string,
  dateTo?: string,
): Promise<PagedWallet> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/wallet/transactions`,
      {
        params: {
          page: pageNumber,
          page_size: itemsPerPage,
          date_from: dateFrom,
          date_to: dateTo,
          show_purchases: showPurchases,
        },
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as Wallet[],
      };
    });
}

export function fetchWalletBalance(
  companyId: string,
): Promise<WalletBalance[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/wallet/balance`,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data.data as WalletBalance[]);
}

export function fetchTalexioParent(
  companyId: string,
): Promise<TalexioParent[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/wallet/talexio_parent`,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data.data as TalexioParent[]);
}

export function fetchSubCompanies(
  companyId: string,
): Promise<TalexioSubcompanies[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/${companyId}/wallet/subcompanies`,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data.data as TalexioSubcompanies[]);
}

export function fetchCreditInvoice(creditId: string): Promise<WalletInvoice[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/wallet/${creditId}`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => response.data.data as WalletInvoice[]);
}

export function fetchCreditExpenditure(
  creditId: string,
): Promise<CreditExpenditureItem[]> {
  return axios
    .get(
      `${Config.API_BASE_URL}intranet/companies/wallet/${creditId}/expenditure`,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => response.data.data as CreditExpenditureItem[]);
}
