import axios from 'axios';
import { Config } from 'config';
import { getAccessToken } from 'lib/Helper/Auth';
import {
  NpsComment,
  NpsCompany,
  NpsLeader,
  NpsMetrics,
  NpsResponse,
  NpsTag,
  PagedNpsResponse,
} from 'lib/Model/Nps';

export function fetchNpsResponses(
  pageNumber: number,
  itemsPerPage: number,
  search?: string,
  submissionStatus?: string,
  ratingType?: string[],
  userType?: string[],
  brand?: string[],
  reviewee?: string[],
  dateFromParam?: string,
  dateToParam?: string,
  companyId?: string,
  dateHandled?: string,
  actionNeeded?: string,
  actionBy?: string,
  team?: string[],
  submissionType?: string,
  tierType?: string,
  showTtvSubmissions?: boolean,
  candidateUserId?: string,
  showCommentRecords?: boolean,
): Promise<PagedNpsResponse> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/nps`, {
      params: {
        page: pageNumber,
        page_size: itemsPerPage,
        company: search,
        submitted: submissionStatus,
        reviewee: reviewee,
        rating_type: ratingType,
        user_type: userType,
        brand_identifier: brand,
        date_from: dateFromParam,
        date_to: dateToParam,
        company_id: companyId,
        date_handled: dateHandled,
        action_needed: actionNeeded,
        action_by: actionBy,
        team_identifier: team,
        submission_type: submissionType,
        tier_type: tierType,
        show_ttv_submissions: showTtvSubmissions,
        candidate_user_id: candidateUserId,
        show_comment_records: showCommentRecords,
      },
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return {
        total_count: response.data.total_count,
        data: response.data.data as NpsResponse[],
      };
    });
}

export function exportNpsResponses(
  search?: string,
  submissionStatus?: string,
  ratingType?: string[],
  userType?: string[],
  brand?: string[],
  reviewee?: string[],
  dateFromParam?: string,
  dateToParam?: string,
  companyId?: string,
  dateHandled?: string,
  actionNeeded?: string,
  actionBy?: string,
  team?: string[],
  submissionType?: string,
  tierType?: string,
  showTtvSubmissions?: boolean,
  candidateUserId?: string,
  showCommentRecords?: boolean,
): Promise<any> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/nps/export`, {
      params: {
        company: search,
        submitted: submissionStatus,
        reviewee: reviewee,
        rating_type: ratingType,
        user_type: userType,
        brand_identifier: brand,
        date_from: dateFromParam,
        date_to: dateToParam,
        company_id: companyId,
        date_handled: dateHandled,
        action_needed: actionNeeded,
        action_by: actionBy,
        team_identifier: team,
        submission_type: submissionType,
        tier_type: tierType,
        show_ttv_submissions: showTtvSubmissions,
        candidate_user_id: candidateUserId,
        show_comment_records: showCommentRecords,
      },
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      // setting todays date and time to the filename. should be ok. overwriting pythons filename
      // TODO: let javascript do the filename, remove the filename creation in python
      const now = new Date();
      const date = now.toISOString().split('T')[0];
      const time = now.toTimeString().split(' ')[0].replace(/:/g, '');
      const formattedDate = date + time;
      const filename = `nps_export_${formattedDate}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    });
}

export function fetchNpsDetails(id: string): Promise<NpsResponse> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/nps/${id}`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data[0] as NpsResponse;
    });
}

export function fetchNpsComments(id: string): Promise<NpsComment[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/nps/${id}/comments`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data.data as NpsComment[];
    });
}

export function addComment(
  npsId: string,
  comment: string,
): Promise<NpsComment> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/nps/${npsId}/comments/new`,
      {
        content: comment,
        comment_type: 'string',
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      // TODO: confirm response is ok
      return response.data as NpsComment;
    });
}

export function updateComment(
  npsId: string,
  commentId: number,
  outcomeComment: string | undefined,
  actionBy: string | undefined,
  completedBy: string | undefined,
  actionNeeded: boolean,
  content: string,
): Promise<NpsComment> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/nps/${npsId}/comments/${commentId}/update`,
      {
        outcome_comment: outcomeComment,
        action_by: actionBy,
        completed_by: completedBy,
        action_needed: actionNeeded,
        content: content,
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      // TODO: confirm response is ok
      return response.data as NpsComment;
    });
}

export function addTag(tag: NpsTag): Promise<NpsTag> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/nps/tags/add`,
      {
        tag_name: tag.tag_name,
        parity: tag.parity,
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      // TODO: confirm response is ok
      return response.data as NpsTag;
    });
}

export function addNps(
  rating: number,
  userEmail: string,
  userFirstName: string,
  userLastName: string,
  userContactNumber: string,
  company: string,
  company_id: string,
  userType: string,
  brandIdentifier: string,
  reviewee: string,
  isTtvSubmission: boolean,
  tags: number[],
): Promise<NpsResponse> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/nps`,
      {
        new_record: {
          rating,
          rating_type: 'string',
          user_email: userEmail,
          user_first_name: userFirstName,
          user_last_name: userLastName,
          user_contact_number: userContactNumber,
          company,
          company_id,
          user_type: userType,
          brand_identifier: brandIdentifier,
          reviewee,
          tags: tags.join(','),
          is_ttv_submission: isTtvSubmission,
        },
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      // TODO: confirm response is ok
      return response.data as NpsResponse;
    });
}

export async function updateTag(tag: NpsTag): Promise<NpsTag> {
  const response = await axios.post(
    `${Config.API_BASE_URL}intranet/nps/tags/${tag.id}/update`,
    {
      id: tag.id,
      tag_name: tag.tag_name,
      parity: tag.parity,
    },
    {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    },
  );
  return response.data as NpsTag;
}

export function updateNps(
  npsId: string,
  rating: number,
  userEmail: string,
  userFirstName: string,
  userLastName: string,
  userContactNumber: string,
  company: string,
  company_id: string,
  userType: string,
  brandIdentifier: string,
  reviewee: string,
  dateHandled: string,
  isTtvSubmission: boolean,
  addTags: string[],
  removeTags: string[],
): Promise<NpsResponse> {
  return axios
    .post(
      `${Config.API_BASE_URL}intranet/nps/${npsId}`,
      {
        update_fields: {
          rating,
          user_email: userEmail,
          user_first_name: userFirstName,
          user_last_name: userLastName,
          user_contact_number: userContactNumber,
          company,
          company_id,
          user_type: userType,
          brand_identifier: brandIdentifier,
          reviewee,
          date_handled: dateHandled,
          is_ttv_submission: isTtvSubmission,
        },
        tags_changes: {
          add: addTags,
          remove: removeTags,
        },
      },
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          'access-token': getAccessToken(),
        },
      },
    )
    .then((response) => {
      // TODO: confirm response is ok
      return response.data as NpsResponse;
    });
}

export function fetchTags(): Promise<NpsTag[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/nps/tagsList`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data.data as NpsTag[];
    });
}

export function fetchNpsScore(companyId: string): Promise<NpsMetrics> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/companies/${companyId}/nps/score`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data as NpsMetrics;
    });
}

export function fetchNpsLeaders(): Promise<NpsLeader[]> {
  // TODO: fix endpoint so it does not have TODO
  return axios
    .get(`${Config.API_BASE_URL}intranet/nps/leaders`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data.data as NpsLeader[];
    });
}

export function fetchNpsCompanies(
  brand_identifier: string,
): Promise<NpsCompany[]> {
  return axios
    .get(`${Config.API_BASE_URL}intranet/nps/${brand_identifier}/companies`, {
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        'access-token': getAccessToken(),
      },
    })
    .then((response) => {
      return response.data.data as NpsCompany[];
    });
}
